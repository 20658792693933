<template>
  <el-drawer
    :wrapperClosable="false"
    custom-class="room-add-room"
    :title="title || '编辑'"
    :visible="drawer"
    :before-close="close"
    :direction="'rtl'"
    :size="350"
  >
    <div class="add-room-body">
      <div class="add-room-title require">姓名</div>
      <my-input v-model="whiteParams.setterName" placeholder="请填写姓名" />
      <div class="add-room-title require">手机号码</div>
      <el-input v-model="whiteParams.phone" placeholder="请填写手机号码" />
      <div class="add-room-title require">学校名称</div>
      <el-select
      class="slll"
        multiple
        style="width: 100%"
        v-model="whiteParams.universityIds"
        placeholder="请选择"
      >
        <el-option
          v-for="item in schoolList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="add-room-submit w-100_ d-f ai-c">
      <el-button type="primary" @click="addRoomFunc">保 存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-drawer>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("safely");
export default {
  name: "school-params-change",
  data() {
    return {
      drawer: false, //  抽屉
      whiteParams: {
        setterName: "",
        phone: "",
        universityIds: [],
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    addRoomFunc() {
      const warning = {
        setterName: "请输入姓名",
        phone: "请输入手机号码",
      };
      const list = Object.keys(warning);
      let canIgo = true;
      for (let i = 0; i < list.length; i++) {
        if (!String(this.whiteParams[list[i]])) {
          this.$message.error(warning[list[i]]);
          canIgo = false;
          break;
        }
      }
      if (!this.whiteParams.universityIds.length) {
        this.$message.error("请至少选择一个学校");
        return;
      }
      if (canIgo) {
        this.close();
        const params = { ...this.whiteParams };
        params.universityNames = this.schoolList
          .reduce(
            (c, n) =>
              params.universityIds.includes(n.id) ? [...c, n.name] : c,
            []
          )
          .join("/");
        params.universityIds = params.universityIds.join(",");
        this.$emit("submit", params);
      }
    },
  },
  watch: {
    drawer() {
      this.whiteParams = {
        setterName: "",
        phone: "",
        universityIds: [],
      };
    },
    visible(val) {
      this.drawer = val;
    },
    data(val) {
      this.whiteParams = val;
    },
  },
  props: {
    visible: Boolean,
    title: String,
    data: Object,
  },
  computed: {
    ...mapState(["schoolList"]),
  },
};
</script>

<style lang="scss" scoped>
.room-add-room {
  .add-room-body {
    padding: 0 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    height: calc(100% - 56px);
    .add-room-title {
      line-height: 50px;
      color: #262626;
      font-weight: bold;
    }
  }
  .add-room-submit {
    bottom: 0;
    height: 56px;
    background: #fbfbfb;
    padding: 0 24px;
  }
}
</style>