<template>
  <div class="h-100_">
    <content-header title="安装员管理">
      <div class="d-f ai-c">
        <span
          style="
            margin: 0 13px;
            font-size: 13px;
            font-weight: 400;
            color: #666666;
          "
          >当前人数：{{ total }}</span
        >
        <el-button type="primary" @click="addVisible = true"
          >添加安装管理员</el-button
        >
      </div>
    </content-header>
    <content-table
      ref="table"
      :total="total"
      :columns="columns"
      :setting="setting"
      :getTableData="getTableData"
      :tableList="tableList"
      @delete="deleteItem"
      @edit-outline="edit"
    ></content-table>
    <setter-drawer
      title="添加安装管理员"
      :visible.sync="addVisible"
      @submit="submit"
    ></setter-drawer>
    <setter-drawer
      title="编辑安装管理员"
      :visible.sync="editVisible"
      :data="editData"
      @submit="editSure"
    ></setter-drawer>
  </div>
</template>

<script>
import ContentHeader from "@/components/contentHeader.vue";
import ContentTable from "@/components/contentTable.vue";
import setterDrawer from "./drawer.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("safely");
export default {
  name: "school",
  components: {
    ContentHeader,
    ContentTable,
    setterDrawer,
  },
  data() {
    return {
      columns: [
        { label: "姓名", prop: "setterName", width: "80" },
        { label: "手机号", prop: "phone", width: "120" },
        { label: "学校列表", prop: "universityNames" },
      ],
      setting: ["edit-outline", "delete"],
      addVisible: false,
      editVisible: false,
      editData: null,
    };
  },
  methods: {
    ...mapActions([
      "getTableData",
      "addTableData",
      "modifyTableData",
      "deleteTableData",
      "getAllSchoolList",
    ]),
    getTableDataList(val) {
      this.getTableData(val);
    },
    submit(val) {
      this.addTableData(val);
    },
    deleteItem(val) {
      //  删除改行
      this.$confirm(
        "确定要删除【" + val.setterName + "】这条数据吗？",
        "删除提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.deleteTableData(val.id);
      });
    },
    edit(val) {
      //  编辑该行
      this.editData = {
        ...val,
        universityIds: val.universityIds.split(",").map(Number),
      };
      this.editVisible = true;
    },
    editSure(val) {
      this.modifyTableData(val).then(()=>this.$message.success('安装员信息修改成功'));
    },
  },
  mounted() {
    this.$refs.table.getDataInit();
    this.getAllSchoolList();
  },
  computed: {
    ...mapState(["total", "tableList"]),
  },
};
</script>
<style lang="scss" scoped>
</style>